import React, { useState } from 'react';
import Container from '../common/Container';
import { Button, Textarea, TextInput } from "evergreen-ui";

const Contact = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const handleChange = (event: any) => {
        switch (event.target.name) {
            case "name":
                setName(event.target.value);
                break;
            case "email":
                setEmail(event.target.value);
                break;
            case "message":
                setEmail(event.target.value);
                break;
            default:
                console.log("Please specify a form field")
        }
        console.log(event.target.name);
    };

    return (
        <Container>
            <h1>contact.</h1>
            <form method={"POST"} action={"https://sendpoint.io/id/ninetyone"}>
                <div>
                    <TextInput
                        required
                        onChange={(event: any) => handleChange(event)}
                        marginBottom={10}
                        name={"name"}
                        type={"text"}
                        placeholder={"name"}
                    />
                </div>
                <div>
                    <TextInput
                        required
                        marginBottom={10}
                        name={"email"}
                        type={"email"}
                        placeholder={"email"}
                    />
                </div>
                <div>
                    <Textarea
                        marginBottom={10}
                        name={"message"}
                        type={"text"}
                        placeholder={"message"}
                    />
                </div>
                <Button appearance={"default"} type={"submit"}>reach out.</Button>
            </form>
        </Container>
    )
};
export default Contact;
