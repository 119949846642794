import React from 'react';
import Container from '../common/Container';
import { KeyControlIcon } from "evergreen-ui";

const About = () => {
    return (
        <Container>
            <div style={{width: "50%"}}>
                <p><u>ninetyone</u> is a <b>software consultancy</b> that takes an unconventional approach to
                    solving the <b>world's biggest problems.</b></p>
            </div>
            <div>
                <a href={"https://twitter.com/ninetyonegroup"} target={"_blank"}>
                    <KeyControlIcon size={50} color={"#333"} />
                </a>
            </div>
        </Container>
    )
};
export default About;
