import React from 'react';
import Container from '../common/Container';
import logo from "../assets/img/logo.svg";
import {Link} from "react-router-dom";

const Home = () => {
    return (
        <Container>
            <Link to={"/about"}>
                <img src={logo} className="logo" alt="logo"/>
            </Link>
        </Container>
    )
};
export default Home;
