// @ts-ignore
import React, {useState} from 'react';
import {Pane, Tab, TabNavigation} from "evergreen-ui";
import Logo from "../assets/img/logo.svg";
import {Link} from "react-router-dom";


const Nav = () => {
    const [tabs, setTabs] = useState(["home", "about", "contact"]);
    const [tabIndex, setTabIndex] = useState(0);
    return (
        <Pane
            paddingLeft={20}
            paddingRight={20}
            height={65}
            width={"100%"}
            display={"flex"}
        >
            <Pane
                display={"flex"}
                width={100}
                height={65}
                marginRight={"auto"}
                alignItems={"center"}
                justifyContent={"flex-start"}
            >
                <Link to={"/"}>
                    <img
                        style={{
                            display: "flex",
                            justifyContent: "flex-start"
                        }}
                        src={Logo}
                        width={50}
                        height={"auto"}
                        alt={"ninetyone logo"}
                    />
                </Link>
            </Pane>
            <Pane
                display={"flex"}
                width={300}
                height={65}
                marginLeft={"auto"}
                alignItems={"center"}
                justifyContent={"flex-end"}
            >
                <TabNavigation marginRight={24}>
                    {tabs.map((tab, index) => (
                        <Link style={{textDecoration: "none"}}
                              to={tabs[index].toLowerCase() == "home" ? "/" : (tabs[index]).toLowerCase()}>
                            <Tab
                                key={tab}
                                id={tab}
                                onSelect={() => {
                                    setTabIndex(index);
                                }}
                                isSelected={index === tabIndex}
                                aria-controls={`panel-${tab}`}
                            >
                                {tab}
                            </Tab>
                        </Link>
                    ))}
                </TabNavigation>
            </Pane>
        </Pane>
    )
};

export default Nav;
