import React from 'react';
import Container from '../common/Container';

const ThankYou = () => {
    return (
        <Container>
            <h2>thank you.</h2>
        </Container>
    )
};
export default ThankYou;
