import React from "react";
import Nav from "./Nav";

const NAV_HEIGHT = 65;
// @ts-ignore
const Container = ({ children }) => {
    return(
        <div className="App">
            <Nav/>
            <header className="header">
                { children }
            </header>
        </div>
    )
};

export default Container;
